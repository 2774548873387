@import "../Styles/Colours.module.scss";

.callToAction {
  text-decoration: none;
  color: #fff;
  background-color: $primary-colour;
  padding: 1em;
  display: flex;
  gap: 1em;
  align-items: center;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      height: 100%;
      font-size: 1.5em;
    }

    .description {
      margin: 0;
    }
  }
}
