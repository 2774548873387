@mixin site-padding {
  margin-left: 5rem;
  margin-right: 5rem;
}

@mixin site-padding-sm {
  margin-left: 1rem;
  margin-right: 1rem;
}

@mixin offset-background($size: 2em, $colour: $primary-colour) {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-top: $size;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    background-color: $colour;
    right: -$size;
    top: -$size;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
