@import "../Styles/Colours.module.scss";
@import "../Styles/Layout.module.scss";

.footer {
  background-color: $primary-colour;
  padding: 1em 0;
  color: $secondary-colour;
  bottom: 0;
  flex-shrink: 0;

  a {
    text-decoration: none;
    color: $secondary-colour;
  }

  .footerContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 5rem;
    max-width: 2600px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 100%;
    max-width: 350px;
  }

  .left,
  .right {
    flex: 50%;
    box-sizing: border-box;
    padding: 1em;
    min-width: 300px;
  }

  .left {
    padding-right: 10em;

    .contact {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 1em;
    }
  }

  .right {
    border-left: 2px solid $secondary-colour;
    padding-left: 5em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .links {
      margin-top: 15px;
    }

    small {
      margin-top: 1em;
    }
  }

  @media (max-width: 1000px) {
    .footerContainer {
      padding: 1em 1rem;
    }

    .left {
      padding-right: 5em;
    }
  }

  @media (max-width: 800px) {
    .left,
    .right {
      flex: 100%;
      padding-left: unset;
    }

    .right {
      border-left: unset;

      .links {
        margin-top: unset;
      }
    }
  }
}
