@import "../Styles/Colours.module.scss";

.descriptiveList {
  dt {
    border: 1px solid $primary-colour;
    padding: 13px;
    border-radius: 0px 0px 0 0;
  }

  dd {
    margin-left: 0;
    border-bottom: 1px solid $primary-colour;
    border-right: 1px solid $primary-colour;
    border-left: 1px solid $primary-colour;
    padding: 13px;
    margin-bottom: 15px;
    border-radius: 0 0 0px 0px;
    background: $light-colour;
  }
}
