.servicesBackground {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
  background-color: #999999;

  @media (max-width: 700px) {
    background-image: unset !important;
  }
}

.overview {
  .title {
    color: #fff;
    width: 30%;
  }

  .linksToServices {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    min-height: 200px;
  }

  @media (max-width: 700px) {
    .title {
      width: 100%;
    }
  }
}

.accountingServices,
.companySecretarial,
.businessSupport,
.financialManagement {
  .title {
    color: #fff;
    width: 30%;
  }

  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;

    .left,
    .right {
      width: 48%;
    }
  }

  @media (max-width: 700px) {
    .title {
      width: 100%;
    }

    .page {
      .left,
      .right {
        width: 100%;
      }
    }
  }
}
