@import "../../Styles/Colours.module.scss";
@import "../../Styles/Layout.module.scss";

.navigation {
  background-color: $primary-colour;
  color: #fff;
  z-index: 100;

  .navigationContainer {
    @include site-padding;
    padding-left: 5rem;
    padding-right: 5rem;
    max-width: 2600px;
    margin-left: auto;
    margin-right: auto;

    overflow: hidden;

    .navItem {
      float: left;

      .active {
        background-color: $dark-colour;
      }

      a {
        display: block;
        color: #fff;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;

        &:hover {
          background-color: $dark-colour;
        }
      }

      &.right {
        float: right;
      }
    }

    .navItemDropdown {
      float: left;

      .active {
        background-color: $dark-colour;
      }

      button {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 14px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
      }

      .dropdownContent {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
          float: none;
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          text-align: left;

          &:hover {
            background-color: #ddd;
          }
        }
      }

      &.show,
      &:hover {
        background-color: $dark-colour;
      }

      &.show .dropdownContent,
      &:hover .dropdownContent {
        display: block;
      }
    }

    @media (max-width: 983px) {
      padding-left: 1rem;
      padding-right: 1rem;

      .navItem {
        a {
          font-size: 0.9em;
          padding: 14px 10px;
        }
      }

      .navItemDropdown {
        button {
          font-size: 0.9em;
          padding: 14px 10px;
        }
      }
    }
  }
}
