@import "../Styles/Colours.module.scss";
@import "../Styles/Layout.module.scss";

.pageSectionGutter {
  margin-left: -5rem;
  margin-right: -5rem;

  .pageSectionBackground {
    max-width: 2600px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;

    .pageSection {
      padding: 5em;

      &.light {
        background-color: #fff;
      }
    }
  }

  @media (max-width: 982px) {
    .pageSectionBackground {
      .pageSection {
        padding: 2em;
      }
    }
  }
}
