@import "./Styles/Colours.module.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $secondary-colour;
}

div[id="root"] {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  align-self: flex-start;
  cursor: pointer;
  border: unset;
  padding: 1em 2em;
  background-color: $primary-colour;
  color: #fff;

  &:hover {
    background-color: $dark-colour;
  }

  &[class="light"] {
    background-color: #f8f9fa;
    color: $primary-colour;

    &:hover {
      background-color: #d3d4d5;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .error {
    color: $danger-colour;
  }

  input,
  textarea {
    padding: 1em;

    &.error {
      border: 1px solid $danger-colour;
      outline: $danger-colour;
    }
  }

  input[type="submit"],
  button[type="submit"] {
    background-color: $primary-colour;
    border: unset;
    color: #fff;

    &:hover {
      background-color: $dark-colour;
    }
  }

  input[type="button"],
  button[type="button"] {
    background-color: $primary-colour;
    border: unset;
    color: #fff;

    &:hover {
      background-color: $dark-colour;
    }
  }

  input[type="submit"],
  button {
    align-self: flex-start;
    cursor: pointer;
    padding: 1em 2em;
  }
}

:global {
  table {
    width: 100%;
    margin-bottom: 25px;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  .table th {
    background-color: $primary-colour !important;
    color: white;
  }

  th {
    text-align: left;
  }

  tbody td {
    background: transparent;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #dddddd;
  }

  .table > thead > tr > th,
  .table-bordered > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #dddddd;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > td,
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    border: 1px solid #dddddd;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table > thead > tr > th,
  .table > thead > tr > td {
    border-bottom-width: 2px;
  }

  .table > caption + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > th,
  .table > thead:first-child > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  .table-bordered,
  .table {
    border: 1px solid #dddddd;
  }

  .table > tbody > tr:nth-child(odd) > td,
  .table > tbody > tr:nth-child(odd) > th,
  .table-bordered > tbody > tr:nth-child(odd) > td,
  .table-bordered > tbody > tr:nth-child(odd) > th {
    background-color: #f9f9f9;
  }

  @media (max-width: 767px) {
    .table th,
    .table td {
      font-size: 11px;
    }
  }
}
