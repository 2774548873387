@import "../Styles/Colours.module.scss";

.contactForm {
  margin-top: 1em;

  form {
    align-items: flex-start;

    .formContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      width: 100%;

      .contactInfo {
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex: 1 1 300px;
      }

      .message {
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex: 1 1 300px;
      }
    }

    textarea {
      height: 100%;
    }

    input[type="submit"] {
      cursor: pointer;
      padding: 1em 2em;
      background-color: $primary-colour;
      border: unset;
      color: #fff;

      &:hover {
        background-color: $dark-colour;
      }
    }
  }
}
