@import "../Styles/Colours.module.scss";

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  width: 100%;
}

.card {
  height: 200px;
  width: 175px;

  background-color: #fff;
  text-decoration: none;
  color: #000;

  .background {
    max-height: 60%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .content {
    padding: 1em;
    max-height: 40%;
    height: auto;

    .title {
      text-align: center;
    }

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &.dark {
    background-color: $secondary-colour;
  }

  &:hover {
    background-color: $primary-colour;
    color: white;
  }

  &.lg {
    height: 325px;
    width: 300px;

    .content {
      .title {
        text-align: left;
      }
    }
  }
}
