@import "../Styles/Colours.module.scss";
@import "../Styles/Layout.module.scss";

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    @include site-padding;
    flex: 1 0 auto;
  }

  .home {
    .background {
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: center;
      background-color: #436015;

      @media (max-width: 740px) {
        background-image: unset !important;
      }
    }

    .welcome {
      color: #fff;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      h2 {
        margin-top: 0;
      }

      h3 {
        margin: 0;
      }

      .bulletList {
        display: flex;
        align-items: center;
        gap: 1em;
        padding-left: 1em;

        span {
          display: flex;
          align-items: center;
          line-height: 100%;
          gap: 0.5em;

          svg {
            font-size: 0.5em;
          }
        }
      }
    }

    .services {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      p {
        max-width: 800px;
      }

      .linksToServices {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1em;
        min-height: 200px;
      }
    }

    .resources {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      p {
        max-width: 800px;
      }

      .linksToResources {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1em;
        min-height: 200px;
      }
    }

    .testimonials {
      h2 {
        text-align: center;
      }
    }

    @media (max-width: 982px) {
      .welcome {
        .bulletList {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .about {
    text-align: center;

    .profiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1em;

      img {
        width: 300px;
        height: 300px;
        border-radius: 50%;

        object-fit: cover;
        object-position: left;
      }
    }

    .testimonials {
      text-align: left;

      h2 {
        text-align: center;
      }
    }
  }

  .contact {
    h1 {
      margin: 0;
    }

    .details {
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      margin: 1em 0;

      .detailsCard {
        padding: 1em;
        background-color: $primary-colour;
        color: #fff;
        height: 100%;
        text-decoration: none;

        &:hover {
          background-color: $dark-colour;
        }

        p {
          margin: 0;

          svg {
            margin-right: 0.5em;
          }
        }
      }
    }

    .map {
      margin: -5em;

      iframe {
        width: 100%;
      }
    }
  }

  .legals {
    div[class*="PageSection_pageSectionBackground"] {
      background-position: center;
    }

    .col6 {
      display: flex;
      justify-content: center;
      gap: 1em;

      > * {
        flex: 1 1 50%;
      }
    }

    @media (max-width: 767px) {
      .tablePrivacy ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }

    @media (max-width: 720px) {
      .col6 {
        flex-wrap: wrap;

        > * {
          width: 100%;
        }
      }
    }

    .title {
      width: 50%;
      color: #fff;
    }

    @media (max-width: 600px) {
      div[class*="PageSection_pageSectionBackground"] {
        background-position: unset;
      }

      .title {
        width: 80%;
      }
    }

    @media (max-width: 535px) {
      .title {
        width: 100%;
      }
    }
  }

  .siteMapSite {
    .title {
      color: #fff;
    }

    .siteMap {
      margin: 0;
      padding: 0;

      ul {
        background-color: #fff;
      }

      li {
        list-style: none;
        border-bottom: solid 1px $primary-colour;
        padding: 0.5em;

        &:hover {
          background-color: $secondary-colour;
        }

        a {
          text-decoration: none;
          color: black;
        }
      }

      ul li ul li:last-child {
        border-bottom: unset;
      }
    }
  }

  .noMatch {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;

    img {
      width: 100%;
      max-width: 400px;
      height: auto;
    }

    a {
      display: inline-block;
      padding: 1em;
      text-decoration: none;
      color: #fff;
      background-color: $primary-colour;

      &:hover {
        background-color: $dark-colour;
      }
    }
  }
}
