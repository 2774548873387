@import "../Styles/Layout.module.scss";
@import "../Styles/Colours.module.scss";

.header {
  .headerContainer {
    max-width: 2600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em 5rem;
    display: flex;
    align-items: center;
    gap: 1em;

    .title {
      font-size: 1.5em;
      font-weight: bold;
      color: $primary-colour;
    }

    img {
      height: 50px;
    }

    a {
      text-decoration: none;
      color: $primary-colour;
    }

    .contactDetails {
      margin-left: auto;
      color: black;
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      justify-content: flex-end;

      & a {
        color: black;
      }

      svg {
        color: $primary-colour;
      }
    }
  }

  @media (max-width: 983px) {
    .headerContainer {
      padding: 0.5em 1rem;

      .title {
        font-size: 1.17em;
      }
    }
  }

  @media (max-width: 748px) {
    .headerContainer {
      .contactDetails {
        display: none;
      }
    }
  }
}
