@import "../Styles/Colours.module.scss";

.listGroup {
  margin-bottom: 20px;
  padding-left: 0;

  & li {
    list-style: none;
  }

  .listGroupItem {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #ffffff;
    border: 1px solid #dddddd;

    &:hover {
      background-color: $secondary-colour;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  a {
    &.listGroupItem {
      color: #555555;
      text-decoration: none;
    }
  }

  &.isDark {
    .listGroupItem {
      background-color: $secondary-colour;
      border-color: #dddddd;

      &:hover {
        background-color: #ffffff;
      }
    }

    a {
      color: #555555;
    }
  }
}
